.project {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 2rem 0 4rem;
}

.project h1 {
  text-align: center;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.project h2 {
  text-align: center;
}

.project-container {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
}

.project-items {
  flex: 1 1 40%;
  text-decoration: none;
  color: black;
  padding: 0 2rem 2rem;
  margin-bottom: 0.5rem;
  text-align: justify;
  text-justify: inter-word;
}

.project-items h2 {
  text-align: left;
}

.project-items a {
  text-decoration: none;
  color: green;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
  padding-top: 0.1rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.project-items a:hover {
  background-color: rgba(144, 238, 144, 0.288);
}

@media screen and (min-width: 72.75rem) {
  .project {
    width: 72.75rem;
    margin: 0 auto;
  }
}
