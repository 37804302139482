.about {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 2rem 2rem 0rem;
}

.container {
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: flex-start;
  padding-top: 1rem;
}

.container img {
  padding-top: 1.5rem;
  flex: 1 1 0;
}

.container .text {
  flex: 4 1 0;
  min-width: 15rem;
  text-align: justify;
  padding-right: 0.5rem;
}

@media screen and (min-width: 72.75rem) {
  .about {
    width: 68.75rem;
    margin: 0 auto;
  }
}
