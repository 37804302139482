.home {
  background: rgb(0, 51, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 51, 0, 1) 0%,
    rgba(0, 70, 0, 1) 81%
  );
  height: 45vh;
}

.greeting {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 2rem 0rem;
  height: 40vh;
  color: white;
}

.greeting h1 {
  font-size: 2.1rem;
  margin: 0;
}

.greeting h2 {
  font-size: 1.5rem;
}

@media screen and (min-width: 72.75rem) {
  .greeting {
    width: 68.75rem;
    margin: 0 auto;
  }
}
