.nav-bar-wide nav ul li {
  display: inline-block;
  margin: 16px 20px 20px
}

.nav-bar-wide nav ul li a:hover {
  border-bottom: 4px solid rgba(144, 238, 144, 0.6);
}

@media screen and (max-width: 39.45rem) {
  .nav-bar-wide {
    display: none;
  }
}
